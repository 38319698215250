import * as React from "react";

const SvgLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1080 1080" {...props}>
    <path
      d="M519.7 944.32V816.34l-111.53 63.72-20.3-35.15 131.83-75.8v-196.1l-169.71 98.32v151.6h-41.22V694.4l-110.96 64.27-20.35-35.15 111-64.27-111-63.72 20.35-35.7 131.26 75.8 170.32-97.77-170.32-97.77-131.26 75.25-20.35-35.15 111-63.72-111-64.27 20.35-35.15 110.96 63.72V252.79h41.22v151.6l169.71 98.32V306.06l-131.3-75.25 20.35-35.7 110.96 64.27V131.4h40.65v127.98l110.96-64.27 20.3 35.7-131.26 75.25v196.65l169.75-98.32V252.76h40.6v127.98l111-63.72 20.83 35.15-110.91 64.27 110.91 63.72-20.3 35.15-131.83-75.25-169.71 97.77 169.71 97.77 131.3-75.8 20.83 35.7-110.91 63.72 110.91 64.27-20.3 35.15-111.53-64.27V822.9h-40.6V671.3l-169.75-98.32v196.1l131.83 75.8-20.35 35.15-111.48-63.72v127.98h-40.65Z"
      style={{
        fill: "#231f20",
      }}
    />
  </svg>
);

export default SvgLogo;
