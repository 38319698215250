import './App.css';
import SvgText from "./svg/Text";
import SvgLogo from "./svg/Logo";

function App() {
  return (
    <div className={"main-logo-grid"}>
      <div className={"rotating-outer-container"}>
        <SvgText className={"rot_text"}/>
      </div>
      <div className={"logo-container"}>
        <div className={"make-middle"}>
        <SvgLogo className={"still_logo"}/>
        </div>
      </div>
    </div>
  );
}

export default App;
